import React, { useState, Fragment, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftCircleFilled,
} from "@ant-design/icons";

import HeaderBar from "../../../Components/Header/HeaderBar";
import Sidebar from "../../../Components/SideBar";
import { Images } from "../../../Themes";
import {
  Modal,
  Spin,
  Form,
  Select,
  message,
  Checkbox,
  Button,
  Tooltip,
} from "antd";
import { RightCircleTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import history from "../../../services/history";
import { apiRefresh } from "../../../services/api";

const DocumentSettings = ({ onGetUserDetail, onSetActiveInnerTab }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createMode, setCreateMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [folderCheck, setFolderCheck] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    setLoading(true);
    onGetUserDetail()
      .then((res) => {
        setLoading(false);
        setFolderCheck(res.payload.isSaveDocument);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [onGetUserDetail]);

  const onTargetChecked = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setFolderCheck(e.target.checked);

    const saveDocOpt = {
      url: `v1/users/ToggleSaveEmailDocument`,
    };
    saveDocOpt.types = [
      "SAVE_DOCUMENT_SETTINGS_SUCCESS",
      "SAVE_DOCUMENT_SETTINGS_FAILURE",
    ];
    setLoading(true);
    apiRefresh.get(saveDocOpt).then((res) => {
      setLoading(false);
    });
    // onAddDocumentView(data).then(() => {
    //   message.success("Successfully Updated!");
    // });
  };

  const [form] = Form.useForm();

  const onDocumentShow = (values) => {
    setLoading(true);
    console.log("valuesvaluesvalues", values);
  };

  // const showModal = (value) => {
  //   setIsModalVisible(true);
  //   setCreateMode(value);
  //   setUpdatedata(value);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="d-flex align-item Reminder-top"
              style={{ justifyContent: "space-between" }}
            >
              <div className="d-flex align-item">
                <div className="client-tag-top">
                  <img src={Images.documentView} className="sus-bottom-icon" />
                </div>
                <span className="top-text">Save Document</span>
                <Tooltip
                  placement="topLeft"
                  title={`This box will enable the automatic saving of any documents you send via email to your clients.`}
                >
                  <img className="ac-info-icon" src={Images.info} />
                </Tooltip>
              </div>

              <LeftCircleFilled
                onClick={() => onSetActiveInnerTab("")}
                className="ac-back-icon"
              />
            </div>
            <div className="default-font-box">
              <div>
                <div className="client-tag-table">
                  <Form
                    onChange={onDocumentShow}
                    form={form}
                    className="d-flex space-between"
                  >
                    <div className="reminder-set-form margin-top-34">
                      <Form.Item className="d-block">
                        <Checkbox
                          checked={folderCheck}
                          onChange={onTargetChecked}
                        >
                          Save Document
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Fragment>
  );
};
export default DocumentSettings;
