import React, { useEffect, Fragment, useState } from "react";
import { DownOutlined, RedoOutlined, UpOutlined } from "@ant-design/icons";
import { Images } from "../../../Themes";
import {
  Table,
  Spin,
  Button,
  Menu,
  Dropdown,
  Form,
  Checkbox,
  DatePicker,
  Select,
  message,
  Modal,
  Row,
  Col,
  Tooltip,
} from "antd";
import { yyyymmddFormate } from "../../../Common/reUseFunction";
import moment from "moment";
import { PaperClipOutlined } from "@ant-design/icons";
import CreateEmailForm from "../CreateEmail/CreateEmailForm";
import { apiRefresh } from "../../../services/api";
import saveAs from "file-saver";

const { Option } = Select;

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

const ClientEmailHistory = ({
  onGetEmployerDocument,
  employerDocumentRes,
  userDataEmp,
  resultList,
  showModal,

  onGetEmployerHistory,
  employerHistoryRes,

  emailImportRes,

  onAddAutoEmailImport,

  onGetClientHistory,
  clientHistoryRes,
  emailContentData,
  onDeleteClientEmail,

  onAutoImportEmailClient,
  onGetAutoImportEmailClient,
  onGetClientFamily,
  clientFamilyData,

  onGetLetterTemplates,
  LetterTemplatesRes,
  onSendEmailLink,
  onAddEmployerEmail,
  onAddDraftClient,
  onGetClientProfile,
  clientProfileData,
  onUploadAvatar,
  onAddEmailDocument,
  emailDocumentRes,
  setHandleKey,
  onGetClientFamilyEmails,
  clientEmailRes,
  onGetImapSettingss,
  imapSettingRes,
  onGetSignature,
  signatureRes,
  onGetPdf,
  onGetLink,
  onGetDocuments,
  documentRes,
  onGetVisaApplication,
  visaAppData,
  onLinkVisa,
  profileData,
  onGetImapForAll,
  imapForAllRes,
  onGetPermProcessingPerson,
  onGetGroups,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [autoEmail, setAutoEmail] = useState(false);
  const [onImport, setOnImport] = useState(false);
  const [onCheck, setOnCheck] = useState(false);
  const [primaryEmail, setPrimaryEmail] = useState(false);
  const [secondaryEmail, setSecondaryEmail] = useState(false);
  const [otherEmail, setOtherEmail] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [onDelete, setOnDelete] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedClient, setSelectedClient] = useState(
    sessionStorage.getItem("clientprofileid")
  );
  const [importSettingData, setImportSettingData] = useState(null);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [emailContent, setEmailContent] = useState(null);
  const [selectedType, setSelectedType] = useState("0");
  const [autoImportPopup, setAutoImportPopup] = useState(false);
  const [autoImport, setAutoImport] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [isReplyAll, setIsReplyAll] = useState(false);
  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [visaList, setVisaList] = useState(false);
  const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
  const [selectEmailLink, setSelectEmailLink] = useState("");
  const [isDraft, setDraft] = useState(false);
  const [primaryEmailAddress, setPrimaryEmailAddress] = useState("");
  const [secondaryEmailAddress, setSecondaryEmailAddress] = useState("");
  const [otherEmailAddress, setOtherEmailAddress] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [CaseId, setCaseId] = useState("0");
  const [oldId, setOldId] = useState("");
  const [loadingPaginate, setLoadingPaginate] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  var clientFamily = [];

  useEffect(() => {
    initialCallApis();
  }, []);

  const initialCallApis = () => {
    setLoading(true);
    let clientProfileIdMain = sessionStorage.getItem("clientProfileIdMain");
    let familyId = sessionStorage.getItem("familyId");
    let data = {
      clientId: clientProfileIdMain,
      familyId: familyId,
    };
    var clientprofileid = sessionStorage.getItem("clientprofileid");
    // onGetImapForAll()
    //   .then((resp) => {
    //     if (!resp.payload.imapForAll) {
    setSelectedType("0");
    setCaseId("0");
    setPageNumber(1);
    onGetAutoImportEmailClient(clientprofileid).then((res) => {
      if (res.payload && res.payload.length > 0) {
        res.payload[0].importStartDate = moment(res.payload[0].importStartDate);
        setImportSettingData(res.payload[0]);
        setOnCheck(res.payload[0].isAutoImportEmail);
        setPrimaryEmail(res.payload[0].isImportFromPrimaryEmail);
        setSecondaryEmail(res.payload[0].isImportFromSecondaryEmail);
        setOtherEmail(res.payload[0].isImportFromOtherEmail);
        // setImportSettingData(new Date());
        formEmail.setFieldsValue({
          isAutoImportEmail: res.payload[0].isAutoImportEmail,
          isImportFromPrimaryEmail: res.payload[0].isImportFromPrimaryEmail,
          isImportFromSecondaryEmail: res.payload[0].isImportFromSecondaryEmail,
          isImportFromOtherEmail: res.payload[0].isImportFromOtherEmail,
          importStartDate: res.payload[0].importStartDate,
        });
        if (res.payload[0].isAutoImportEmail) {
          var emailQueueOpt = {
            url: `v1/ClientEmailImport/ImportEmailQueue`,
          };

          emailQueueOpt.types = ["EMAIL_QUEUE_SUCCESS", "EMAIL_QUEUE_FAILURE"];
          apiRefresh.post(emailQueueOpt, { clientId: clientprofileid });
        }
      }
    });
    //   }
    // })
    // .catch((err) => {});
    onGetClientFamily(data).then((res) => {});

    onGetVisaApplication(clientprofileid).then((res) => {});
    onGetClientHistory(selectedClient, familyId, "0", pageSize, 1, 0)
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setSelectedData([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setSelectedData(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {}, [pageSize, pageNumber]);

  const paginate = (value) => {
    let familyId = sessionStorage.getItem("familyId");
    var pgNum = value.current;
    if (pageSize !== value.pageSize) {
      setPageSize(value.pageSize);
      setPageNumber(1);
      setDefaultCurrent(1);
      pgNum = 1;
    } else {
      setPageSize(value.pageSize);
      setPageNumber(value.current);
    }
    setLoadingPaginate(true);
    onGetClientHistory(
      selectedClient,
      familyId,
      selectedType,
      value.pageSize,
      pgNum,
      CaseId
    )
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setSelectedData([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setSelectedData(filterList);
        }
        setLoadingPaginate(false);
      })
      .catch(() => {
        setSelectedData([]);
        setLoadingPaginate(false);
      });
  };

  useEffect(() => {
    if (imapForAllRes) {
      setOnCheck(imapForAllRes.imapForAll);
      formEmail.setFieldsValue({
        isAutoImportEmail: imapForAllRes.imapForAll,
      });
      if (imapForAllRes.imapForAll) {
        setPrimaryEmail(true);
        setSecondaryEmail(true);
        setOtherEmail(true);
        // setImportSettingData(new Date());
        formEmail.setFieldsValue({
          isAutoImportEmail: true,
          isImportFromPrimaryEmail: true,
          isImportFromSecondaryEmail: true,
          isImportFromOtherEmail: true,
          importStartDate: moment(new Date()),
        });
      }
    }
  }, [imapForAllRes]);

  useEffect(() => {
    let filterList = [];
    let visaListData = [];
    if (visaAppData && visaAppData.items && visaAppData.items.length > 0) {
      visaAppData.items.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        visaListData.push(data);
      });
      setVisaList(visaListData);
    } else setVisaList([]);
    // if (
    //   clientHistoryRes &&
    //   clientHistoryRes.emailHistories &&
    //   clientHistoryRes.emailHistories.length > 0
    // ) {
    //   clientHistoryRes.emailHistories.map((data, index) => {
    //     data.index = index;
    //     data.key = `${index + 1}`;
    //     filterList.push(data);
    //   });
    //   setSelectedData(filterList);
    // } else setSelectedData([]);
  }, [clientHistoryRes, visaAppData]);

  useEffect(() => {
    if (
      clientHistoryRes &&
      clientHistoryRes.emailHistories &&
      clientHistoryRes.emailHistories.length > 0
    ) {
      let filterList = [];
      clientHistoryRes.emailHistories.map((data, index) => {
        data.index = index;
        data.key = `${index + 1}`;
        filterList.push(data);
      });
      setSelectedData(filterList);
    } else setSelectedData([]);
  }, [clientHistoryRes]);

  useEffect(() => {
    if (profileData) {
      if (profileData.primaryEmail) {
        setPrimaryEmailAddress("(" + profileData.primaryEmail + ")");
      }
      if (profileData.secondaryEmail) {
        setSecondaryEmailAddress("(" + profileData.secondaryEmail + ")");
      }
      if (profileData.otherEmail) {
        setOtherEmailAddress("(" + profileData.otherEmail + ")");
      }
    }
  }, [profileData]);

  if (
    clientFamilyData &&
    clientFamilyData.items &&
    clientFamilyData.items.length > 0
  ) {
    clientFamily = [];
    for (var i = 0; i < clientFamilyData.items.length; i++) {
      if (clientFamilyData.items[i].memberType.toLowerCase() == "client") {
        clientFamily.push(
          <Option value={clientFamilyData.items[i].id}>
            {clientFamilyData.items[i].firstName +
              " " +
              clientFamilyData.items[i].lastName +
              " (Applicant)"}
          </Option>
        );
      } else if (
        clientFamilyData.items[i].memberType.toLowerCase() === "spouse"
      ) {
        clientFamily.push(
          <Option value={clientFamilyData.items[i].id}>
            {clientFamilyData.items[i].firstName +
              " " +
              clientFamilyData.items[i].lastName +
              " (Partner)"}
          </Option>
        );
      } else {
        clientFamily.push(
          <Option value={clientFamilyData.items[i].id}>
            {clientFamilyData.items[i].firstName +
              " " +
              clientFamilyData.items[i].lastName +
              " (Member)"}
          </Option>
        );
      }
    }
  }

  function handleMenuClick(e) {
    let familyId = sessionStorage.getItem("familyId");
    console.log("click", e);
    let ids = [];
    if (selectedRowKeys.length > 0) {
      for (var i = 0; i < selectedRowKeys.length; i++) {
        ids.push(selectedRowKeys[i].id);
      }

      setLoading(true);
      onDeleteClientEmail({ ids: ids })
        .then((res) => {
          setLoading(false);
          message.success("Successfully Deleted!");
          onGetClientHistory(
            selectedClient,
            familyId,
            selectedType,
            pageSize,
            "1",
            CaseId
          ).then((res) => {
            if (
              res &&
              res.payload &&
              res.payload.emailHistories &&
              res.payload.emailHistories.length === 0
            ) {
              setSelectedData([]);
              setTotalPages(0);
            } else {
              let filterList = [];
              res &&
                res.payload &&
                res.payload.emailHistories.map((data, index) => {
                  data.index = index;
                  data.key = `${index + 1}`;
                  filterList.push(data);
                });
              setSelectedData(filterList);
              setTotalPages(res && res.payload && res.payload.count);
            }
            setPageNumber(1);
            setDefaultCurrent(1);
          });
          setSelectedRowKeys([]);
        })
        .catch((err) => {
          setSelectedData([]);
          setLoading(false);
        });
    }
  }

  function onDateChange(date, dateString) {
    console.log(date, dateString);
    formEmail.setFieldsValue({
      importStartDate: date,
    });
    setOnImport(date, dateString);
    setSelectedDate(date);
  }

  function onCheckChange(e) {
    formEmail.setFieldsValue({
      isAutoImportEmail: e.target.checked,
    });
    setOnCheck(e.target.checked);
    if (!e.target.checked) {
      formEmail.setFieldsValue({
        importStartDate: "",
        isAutoImportEmail: e.target.checked,
        isImportFromPrimaryEmail: false,
        isImportFromSecondaryEmail: false,
        isImportFromOtherEmail: false,
      });
    }
  }

  function onPrimaryChange(e) {
    console.log(`checked = ${e.target.checked}`);

    setPrimaryEmail(e.target.checked);
  }

  function onSecondaryChange(e) {
    console.log(`checked = ${e.target.checked}`);

    setSecondaryEmail(e.target.checked);
  }

  function onOtherChange(e) {
    console.log(`checked = ${e.target.checked}`);

    setOtherEmail(e.target.checked);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Delete Selected File</Menu.Item>
      {/* <Menu.Item key="2">Download Selected</Menu.Item> */}
    </Menu>
  );

  const [formEmail] = Form.useForm();
  const onEmailFinish = (values) => {
    console.log("valuesvaluesvalues", values);
    var clientprofileid = sessionStorage.getItem("clientprofileid");
    // if (!importSettingData) {
    //   setAutoImportPopup(true);
    // }
    var create = false;
    if (!importSettingData) {
      create = window.confirm("Are you sure you want to enable auto import?");
    }

    if (create || importSettingData) {
      setLoading(true);
      const JsonData = {
        clientImportSettingsId: importSettingData
          ? importSettingData.clientImportSettingsId
          : 0,
        id: importSettingData
          ? importSettingData.id
          : "00000000-0000-0000-0000-000000000000",
        userId: "00000000-0000-0000-0000-000000000000",
        clientId: clientprofileid,
        isRead: false,
        isAutoImportEmail: values.isAutoImportEmail,
        isImportFromPrimaryEmail: values.isImportFromPrimaryEmail || false,
        isImportFromSecondaryEmail: values.isImportFromSecondaryEmail || false,
        isImportFromOtherEmail: values.isImportFromOtherEmail || false,
        importStartDate: values.importStartDate
          ? moment(values.importStartDate).format("YYYY-MM-DDT00:00:00+00:00")
          : "1900-01-01T00:00:00+00:00",
        istTimeImport: true,
      };

      onAutoImportEmailClient(JsonData)
        .then(() => {
          onGetAutoImportEmailClient(clientprofileid).then((res) => {
            setImportSettingData(res && res.payload[0]);
          });
          setLoading(false);
          message.success("Updated Successfully!");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const confirmation = (value) => {
    setModal1Visible(!modal1Visible);
    setOnDelete(value);
    setTimeout(() => {
      setShowDetailPopup(false);
    }, 10);

    //
    // setOnDelete(value);
  };

  const showLinkVisa = (value) => {
    setShowLinkPopup(true);
    setSelectEmailLink(value.id);
    setTimeout(() => {
      setShowDetailPopup(false);
    }, 10);

    //
    // setOnDelete(value);
  };

  const removeTag = (value) => {
    setLoading(true);
    let familyId = sessionStorage.getItem("familyId");

    const remove = {
      ids: [onDelete],
    };

    onDeleteClientEmail(remove).then(() => {
      // onGetEmployerDocument(userDataEmp && userDataEmp.id);
      onGetClientHistory(
        selectedClient,
        familyId,
        selectedType,
        pageSize,
        "1",
        CaseId
      )
        .then((res) => {
          if (
            res &&
            res.payload &&
            res.payload.emailHistories &&
            res.payload.emailHistories.length === 0
          ) {
            setSelectedData([]);
            setTotalPages(0);
          } else {
            let filterList = [];
            res &&
              res.payload &&
              res.payload.emailHistories.map((data, index) => {
                data.index = index;
                data.key = `${index + 1}`;
                filterList.push(data);
              });
            setSelectedData(filterList);
            setTotalPages(res && res.payload && res.payload.count);
          }
          setPageNumber(1);
          setDefaultCurrent(1);
        })
        .catch(() => {
          setSelectedData([]);
        });
      setLoading(false);
      setModal1Visible(false);
      message.success("Successfully Deleted!");
    });
  };

  const onChangeClient = (val) => {
    setLoading(true);
    setSelectedClient(val);
    let familyId = sessionStorage.getItem("familyId");

    onGetClientHistory(val, familyId, selectedType, pageSize, "1", CaseId)
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setSelectedData([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setSelectedData(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setLoading(false);
        setPageNumber(1);
        setDefaultCurrent(1);
      })
      .catch(() => {
        setSelectedData([]);
        setLoading(false);
      });
  };

  const onChangeType = (val) => {
    setLoading(true);
    setSelectedType(val);
    let familyId = sessionStorage.getItem("familyId");

    onGetClientHistory(selectedClient, familyId, val, pageSize, "1", CaseId)
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setSelectedData([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setSelectedData(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setPageNumber(1);
        setDefaultCurrent(1);
        setLoading(false);
      })
      .catch(() => {
        setSelectedData([]);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "",
      key: "index",
      width: "20px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "",
      key: "index",
      width: "70px",
      render: (text, record) => {
        return (
          <div>
            {record.importText.toLowerCase() == "inbox" ? (
              <span
                className="sent-email"
                style={{ backgroundColor: "#2EC7EA" }}
              >
                Inbox Imported
              </span>
            ) : record.autoImport === true ? (
              <span className="draft" style={{ backgroundColor: "#FCBB3A" }}>
                Sent Imported
              </span>
            ) : record.draft === true ? (
              <span className="draft">Draft</span>
            ) : record.import ? (
              <span className="draft" style={{ backgroundColor: "#FCBB3A" }}>
                Imported
              </span>
            ) : record.isSent === true ? (
              <span className="sent-email">Sent Email</span>
            ) : record.status == "Queued" ? (
              <span>Sending</span>
            ) : (
              <span>{record.status}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "createdDate",
      width: "50px",
      dataIndex: "createdDate",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      render: (text, record) => {
        return <span>{moment(text).format("DD/MM/YYYY hh:mm:ss")}</span>;
      },
    },
    {
      title: "From",
      dataIndex: "from",
      // defaultSortOrder: "descend",
      ellipsis: true,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.from.localeCompare(b.from),
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.from}</span>
          </div>
        );
      },
    },

    {
      title: "To",
      dataIndex: "to",
      // defaultSortOrder: "descend",

      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.to.localeCompare(b.to),
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.to}</span>
          </div>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      // defaultSortOrder: "descend",

      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      width: "70px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>{record && record.subject}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "60px",
      render: (text, record) => {
        return (
          <div style={{ display: "block" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {record.emailAttachment.length > 0 && <PaperClipOutlined />}
              <div
                className="cv-action-icons-border"
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => {
                  // setEmailContent(record);

                  var getEmailOpt = {
                    url: `v1/imap/ClientEmailById/${record.id}/${record.clientId}`,
                  };

                  getEmailOpt.types = [
                    "MOVE_DOCUMENTS_SUCCESS",
                    "MOVE_DOCUMENTS_FAILURE",
                  ];
                  setLoading(true);

                  apiRefresh
                    .get(getEmailOpt)
                    .then((res) => {
                      if (res.emailHistories[0].emailMessage) {
                        res.emailHistories[0].emailMessage = res.emailHistories[0].emailMessage.replace(
                          "float: left;",
                          ""
                        );
                        res.emailHistories[0].emailMessage = res.emailHistories[0].emailMessage.replace(
                          "<img",
                          '<img style="max-width: 822px"'
                        );
                        res.emailHistories[0].emailMessage = res.emailHistories[0].emailMessage.replace(
                          "float:left;",
                          ""
                        );
                      }
                      setLoading(false);
                      setEmailContent(res.emailHistories[0]);
                      setShowDetailPopup(true);
                    })
                    .catch((err) => {
                      setLoading(false);
                    });

                  setOldId(record.oldId);
                  if (record.draft) {
                    setDraft(true);
                  }
                }}
              >
                <img src={Images.detailEmail} className="cv-action-icon" />
              </div>
              <div
                className="cv-action-icons-border"
                style={{ marginLeft: 5, cursor: "pointer" }}
                onClick={() => showLinkVisa(record)}
              >
                <img src={Images.multimediaBlue} className="cv-action-icon" />
              </div>
              <div
                onClick={() => confirmation(record && record.id)}
                className="cv-action-icons-border"
                style={{ marginLeft: 5, cursor: "pointer" }}
              >
                <img src={Images.deleteIcon} className="cv-action-icon" />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const visaColumns = [
    {
      title: "Visa",
      dataIndex: "branchVisaTypeName",
      // defaultSortOrder: "descend",
      ellipsis: true,
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.branchVisaTypeName}
            </span>
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "visaStatusName",
      width: "50px",
      render: (text, record) => {
        return (
          <div>
            <span style={{ fontSize: "12px" }}>
              {record && record.visaStatusName}
            </span>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys, selectedRow) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRow);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          // this.setState({ selectedRowKeys: newSelectedRowKeys });
          setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys({ selectedRowKeys: newSelectedRowKeys });
        },
      },
    ],
  };

  const rowSelection1 = {
    fixed: "left",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys1(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const handleCancelDetailPopup = () => {
    setIsReplyAll(false);
    setIsReply(false);
    setIsForward(false);
    setShowForm(false);
    setDraft(false);
    setShowDetailPopup(false);
  };

  const onOkButtn = () => {
    setShowDetailPopup(false);
  };

  const handleCancelLinkPopup = () => {
    setShowLinkPopup(false);
  };

  const onOkLinkButton = () => {
    let count = 0;
    if (selectedRowKeys1.length > 0) {
      setLoading(true);
      for (var i = 0; i < selectedRowKeys1.length; i++) {
        let data = {
          emailId: parseInt(selectEmailLink),
          subjectCaseId: parseInt(selectedRowKeys1[i].id),
        };
        onLinkVisa(data)
          .then((res) => {
            count++;

            if (count == selectedRowKeys1.length) {
              message.success("Linked!");
              setLoading(false);
              setShowLinkPopup(false);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } else {
      message.warning("No visa selected");
      setShowLinkPopup(false);
    }
  };

  const moveToDocs = (attachment) => {
    var clientprofileid = sessionStorage.getItem("clientprofileid");
    let familyId = sessionStorage.getItem("familyId");
    let formData = new FormData();
    // formData.append("file", selectedFile);
    setLoading(true);
    const JsonData = {
      SubjectId: clientprofileid,
      FamilyId: familyId || "00000000-0000-0000-0000-000000000000",
      DocumentTypeId: 0,
      Title: "",
      SizeInKB: 0,
      DocuementExtension: "",
      IsAgent: false,
      IsClient: false,
      ShowAgent: false,
      ShowClient: false,
      QuestionnaireId: 0,
      DocuementBlobUrl: attachment.blobURL,
      BlobFileName: attachment.name,
      oldId: oldId,
    };
    formData.append("Data", JSON.stringify(JsonData));
    var docTypeOpt = {
      url: `v1/document/FromUrl`,
    };

    docTypeOpt.types = ["MOVE_DOCUMENTS_SUCCESS", "MOVE_DOCUMENTS_FAILURE"];
    apiRefresh
      .post(docTypeOpt, formData)
      .then((res) => {
        message.success("Success!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Failed");
      });
  };

  const onDownloadAttachment = (attachment) => {
    let formData = new FormData();
    if (oldId) {
      var getAttachmentOpt = {
        url: `v1/document/GetOldDocumentBytesforAttachment/${attachment.name}/${attachment.blobURL}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        downlodDocument(res, attachment);
      });
    } else {
      let branchId = localStorage.getItem("selectedBranchId");
      var getAttachmentOpt = {
        url: `v1/document/GetDocumentBytesforAttachment/${attachment.blobURL}/${branchId}`,
      };

      getAttachmentOpt.types = [
        "GET_DOCUMENTS_SUCCESS",
        "GET_DOCUMENTS_FAILURE",
      ];
      apiRefresh.getFile(getAttachmentOpt).then((res) => {
        downlodDocument(res, attachment);
      });
    }
  };

  const downlodDocument = (byte, value) => {
    let blob = new Blob([byte], {
      type: "application/octet-stream",
    });
    saveAs(byte, value.name);
  };

  const visaFilter = (value) => {
    setLoading(true);
    setCaseId(parseInt(value));
    let familyId = sessionStorage.getItem("familyId");
    onGetClientHistory(
      selectedClient,
      familyId,
      selectedType,
      pageSize,
      "1",
      value
    )
      .then((res) => {
        if (
          res &&
          res.payload &&
          res.payload.emailHistories &&
          res.payload.emailHistories.length === 0
        ) {
          setSelectedData([]);
          setTotalPages(0);
        } else {
          let filterList = [];
          res &&
            res.payload &&
            res.payload.emailHistories.map((data, index) => {
              data.index = index;
              data.key = `${index + 1}`;
              filterList.push(data);
            });
          setSelectedData(filterList);
          setTotalPages(res && res.payload && res.payload.count);
        }
        setPageNumber(1);
        setDefaultCurrent(1);
        setLoading(false);
      })
      .catch((err) => {
        setSelectedData([]);
        setLoading(false);
        message.error("Failed!");
      });
  };

  return (
    <Fragment>
      <Spin spinning={loading} size="large">
        <div className="employer-table border-color-blue">
          <div>
            {/* <Select /> */}
            <div className="margin-btm">
              {autoEmail === false ? (
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#1281b9",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onClick={() => setAutoEmail(!autoEmail)}
                >
                  <div>
                    <span style={{ color: "#FFFFFF" }}>Auto Email Import</span>
                  </div>
                  <DownOutlined style={{ color: "#FFFFFF" }} />
                </div>
              ) : (
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    display: "flex",
                    backgroundColor: "#1281b9",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onClick={() => setAutoEmail(!autoEmail)}
                >
                  <div>
                    <span style={{ color: "#FFFFFF" }}>Auto Email Import</span>
                  </div>
                  <UpOutlined style={{ color: "#FFFFFF" }} />
                </div>
              )}
            </div>
            <Modal
              title={false}
              style={{ top: 20 }}
              visible={autoImportPopup}
              onOk={() => {
                setAutoImport(true);
              }}
              onCancel={() => setModal1Visible(false)}
              Header={false}
            >
              <h4>Are you sure you want to enable auto import?</h4>
            </Modal>
            <Modal
              visible={showDetailPopup}
              title={"Email Detail"}
              onCancel={handleCancelDetailPopup}
              // onOk={onOkLinkButton}
              width="900px"
              footer={false}
            >
              <>
                {emailContent && !emailContent.draft && (
                  <div className="client-email-popup-body">
                    {emailContent && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <div className="client-email-label">
                              <span>
                                {"Created Date: " +
                                  moment(emailContent.createdDate).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )}
                              </span>
                            </div>
                            <div
                              className="client-email-label"
                              style={{ marginLeft: 10 }}
                            >
                              <span>
                                {"Created By: " + emailContent.userName}
                              </span>
                            </div>
                          </div>
                          <div>
                            {emailContent.importText.toLowerCase() ==
                            "inbox" ? (
                              <span
                                className="sent-email"
                                style={{ backgroundColor: "#2EC7EA" }}
                              >
                                Inbox Imported
                              </span>
                            ) : emailContent.autoImport === true ? (
                              <span
                                className="draft"
                                style={{ backgroundColor: "#FCBB3A" }}
                              >
                                Sent Imported
                              </span>
                            ) : emailContent.draft === true ? (
                              <span className="draft">Draft</span>
                            ) : emailContent.import ? (
                              <span
                                className="draft"
                                style={{ backgroundColor: "#FCBB3A" }}
                              >
                                Imported
                              </span>
                            ) : emailContent.isSent === true ? (
                              <span className="sent-email">Sent Email</span>
                            ) : emailContent.status == "Queued" ? (
                              <span>Sending</span>
                            ) : (
                              <span>{emailContent.status}</span>
                            )}
                          </div>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"From: " + emailContent.from}
                          </span>
                        </div>
                        <div className="client-email-to-row">
                          <div>
                            <span className="client-email-address">
                              {"To: " + emailContent.to}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"Subject: " + emailContent.subject}
                          </span>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"CC: " + emailContent.cc}
                          </span>
                        </div>
                        <div>
                          <span className="client-email-address">
                            {"Bcc: " + emailContent.bcc}
                          </span>
                        </div>
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: emailContent.emailMessage,
                            }}
                          />
                        </div>
                        {emailContent.emailAttachment.length > 0 && (
                          <div
                            className="body-attachment-row"
                            style={{ display: "inline-block" }}
                          >
                            {emailContent.emailAttachment.map(
                              (attachment, index) => {
                                return (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "inline-block",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <PaperClipOutlined />

                                      <span
                                        className="body-attachment-text"
                                        style={{
                                          fontSize: 12,
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          onDownloadAttachment(attachment)
                                        }
                                      >
                                        {attachment.name}
                                      </span>
                                      <img
                                        src={Images.forward}
                                        style={{
                                          marginLeft: 10,
                                          width: 25,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => moveToDocs(attachment)}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {(showForm || isDraft) && (
                  <CreateEmailForm
                    resultList={resultList}
                    emailContentData={emailContentData}
                    onGetLetterTemplates={onGetLetterTemplates}
                    LetterTemplatesRes={LetterTemplatesRes}
                    onSendEmailLink={onSendEmailLink}
                    onAddEmployerEmail={onAddEmployerEmail}
                    onAddDraftClient={onAddDraftClient}
                    onGetClientProfile={onGetClientProfile}
                    clientProfileData={clientProfileData}
                    setLoading={setLoading}
                    loading={loading}
                    onGetClientHistory={onGetClientHistory}
                    onUploadAvatar={onUploadAvatar}
                    onAddEmailDocument={onAddEmailDocument}
                    emailDocumentRes={emailDocumentRes}
                    setHandleKey={setHandleKey}
                    onGetClientFamilyEmails={onGetClientFamilyEmails}
                    clientEmailRes={clientEmailRes}
                    onGetEmployerDocument={onGetEmployerDocument}
                    employerDocumentRes={employerDocumentRes}
                    onGetImapSettingss={onGetImapSettingss}
                    imapSettingRes={imapSettingRes}
                    onGetSignature={onGetSignature}
                    signatureRes={signatureRes}
                    onGetPdf={onGetPdf}
                    onGetLink={onGetLink}
                    onGetDocuments={onGetDocuments}
                    documentRes={documentRes}
                    inHistoryPopup={true}
                    emailContent={emailContent}
                    forward={isForward}
                    reply={isReply}
                    replyAll={isReplyAll}
                    isDraft={isDraft}
                    handleCancelPopup={handleCancelDetailPopup}
                    onDeleteClientEmail={onDeleteClientEmail}
                    onGetPermProcessingPerson={onGetPermProcessingPerson}
                    onGetGroups={onGetGroups}
                  />
                )}
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      marginLeft:
                        showForm || isDraft
                          ? `calc(100% - 70px)`
                          : `calc(100% - 340px)`,
                      marginTop: 20,
                    }}
                  >
                    {!showForm && !isDraft && (
                      <div style={{ display: "inline-block" }}>
                        <div style={{ display: "inline-block", margin: 5 }}>
                          <Button
                            type="primary"
                            className="login-form-button save-btn button-blue"
                            onClick={() => {
                              setIsForward(true);
                              setShowForm(true);
                            }}
                          >
                            Forward
                          </Button>
                        </div>
                        <div style={{ display: "inline-block", margin: 5 }}>
                          <Button
                            // onClick={() => DraftData()}
                            type="primary"
                            className="login-form-button save-btn button-blue"
                            onClick={() => {
                              setIsReply(true);
                              setShowForm(true);
                            }}
                          >
                            Reply
                          </Button>
                        </div>
                        <div style={{ display: "inline-block", margin: 5 }}>
                          <Button
                            // onClick={() => DraftData()}
                            type="primary"
                            className="login-form-button save-btn button-blue"
                            onClick={() => {
                              setIsReplyAll(true);
                              setShowForm(true);
                            }}
                          >
                            Reply All
                          </Button>
                        </div>
                      </div>
                    )}
                    {/* </Col> */}
                    <div style={{ display: "inline-block", margin: 5 }}>
                      <Button
                        // onClick={() => DraftData()}
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsReplyAll(false);
                          setIsReply(false);
                          setIsForward(false);
                          setShowForm(false);
                          setDraft(false);
                          setShowDetailPopup(false);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                  {/* <Row
                    className="margin-top-12"
                    gutter={10}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Col>
                      {!showForm && !isDraft && (
                        <Row gutter={10}>
                          <Col>
                            <Button
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsForward(true);
                                setShowForm(true);
                              }}
                            >
                              Forward
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              // onClick={() => DraftData()}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsReply(true);
                                setShowForm(true);
                              }}
                            >
                              Reply
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              // onClick={() => DraftData()}
                              type="primary"
                              className="login-form-button save-btn button-blue"
                              onClick={() => {
                                setIsReplyAll(true);
                                setShowForm(true);
                              }}
                            >
                              Reply All
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col>
                      <Button
                        // onClick={() => DraftData()}
                        type="primary"
                        className="login-form-button save-btn button-blue"
                        onClick={() => {
                          setIsReplyAll(false);
                          setIsReply(false);
                          setIsForward(false);
                          setShowForm(false);
                          setDraft(false);
                          setShowDetailPopup(false);
                        }}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row> */}
                </div>
              </>
            </Modal>
            <Modal
              visible={showLinkPopup}
              title={"Link Visa"}
              onCancel={handleCancelLinkPopup}
              onOk={onOkLinkButton}
              width="900px"
            >
              <>
                <div className="client-email-popup-body">
                  <Table
                    className="border-3 "
                    rowSelection={rowSelection1}
                    // showCount={true}
                    columns={visaColumns}
                    dataSource={visaList}
                    pagination={false}
                  />
                </div>
              </>
            </Modal>
            <div className="margin-btm">
              {autoEmail === true && (
                <Form
                  onFinish={onEmailFinish}
                  form={formEmail}
                  className="auto-email margin-btm"
                  // initialValues={importSettingData}
                >
                  <Form.Item name="isAutoImportEmail" valuePropName="checked">
                    <Checkbox onChange={onCheckChange} checked={onCheck}>
                      Auto Import
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{ width: "400px" }}
                    name="importStartDate"
                    rules={[
                      {
                        required: onCheck,
                        message: "Required!",
                      },
                    ]}
                  >
                    <DatePicker
                      format={dateFormat}
                      onChange={onDateChange}
                      // picker="Start Date"
                      disabled={!onCheck}
                    />
                  </Form.Item>
                  {onCheck && (
                    <>
                      <Form.Item
                        name="isImportFromPrimaryEmail"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={onPrimaryChange}>
                          {"Primary Email " + primaryEmailAddress}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="isImportFromSecondaryEmail"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={onSecondaryChange}>
                          {"Secondary Email " + secondaryEmailAddress}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="isImportFromOtherEmail"
                        valuePropName="checked"
                      >
                        <Checkbox onChange={onOtherChange}>
                          {"Other Email " + otherEmailAddress}
                        </Checkbox>
                      </Form.Item>
                    </>
                  )}
                  <Form.Item>
                    <Button
                      className="employer-btn"
                      style={{
                        backgroundColor: "#1081b9 ",
                        border: 0,
                        zIndex: "2",
                      }}
                      // onClick={() => showModal("add-documents")}
                      type="primary"
                      htmlType="submit"
                    >
                      UPDATE
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
          <div style={{ display: "inline-block", width: "100%" }}>
            <div
              style={{
                marginRight: "7px",
                marginBottom: "26px",
                zIndex: 1,
                display: "inline-block",
                width: "29%",
              }}
            >
              <Select
                // style={{ width: 205 }}
                onChange={onChangeClient}
                value={clientFamily.length > 0 ? selectedClient : ""}
              >
                <Option value="00000000-0000-0000-0000-000000000000">
                  All Family
                </Option>
                {clientFamily}
              </Select>
            </div>
            <div
              style={{
                marginRight: "7px",
                marginBottom: "26px",
                zIndex: 1,
                display: "inline-block",
                width: "29%",
              }}
            >
              <Select
                // style={{ width: "205px" }}
                placeholder="Select"
                defaultValue="0"
                onChange={(value) => {
                  if (value == null) {
                    value = "0";
                  }
                  visaFilter(value);
                }}
                allowClear
                value={CaseId}
              >
                <Option value="0">Visa</Option>
                {visaList &&
                  visaList.map((item, index) => (
                    <Option value={item.id}> {item.branchVisaTypeName}</Option>
                  ))}
              </Select>
            </div>
            <div className="dropdown-btn-reload-btn-row">
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: "7px",
                    marginBottom: "26px",
                    zIndex: 1,
                    display: "inline-block",
                    width: "73%",
                  }}
                >
                  <Select
                    className="doc-filter"
                    showSearch
                    // style={{ width: 205 }}
                    placeholder="ALL"
                    optionFilterProp="children"
                    onChange={onChangeType}
                    value={selectedType}
                  >
                    <Option value="0">All</Option>
                    <Option value="1">Imported</Option>
                    <Option value="3">Sent</Option>
                    <Option value="4">Email Not Sent</Option>
                    <Option value="2">Draft</Option>
                  </Select>
                </div>
                <div style={{ display: "inline-block", marginRight: 7 }}>
                  <Tooltip title="Refresh" style={{ display: "inline-block" }}>
                    <div
                      className="reload-email-icon-cont"
                      onClick={initialCallApis}
                    >
                      <RedoOutlined className="reload-email-icon" />
                    </div>
                  </Tooltip>
                </div>
                <div style={{ zIndex: 1, display: "inline-block" }}>
                  <Dropdown.Button overlay={menu}></Dropdown.Button>
                </div>
              </div>
            </div>
          </div>
          <Spin size="large" spinning={loadingPaginate}>
            <Table
              className="border-3 "
              rowSelection={rowSelection}
              showCount={true}
              columns={columns}
              onChange={paginate}
              pagination={{
                defaultCurrent: defaultCurrent,
                current: pageNumber,
                total: totalPages,
                showSizeChanger: true,
                defaultPageSize: 20,
                pageSizeOptions: ["20", "50", "100"],
              }}
              scroll={{ x: "auto" }}
              dataSource={selectedData}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       setEmailContent(record);
              //       setShowDetailPopup(true);
              //     }, // click row
              //     // onDoubleClick: (event) => {}, // double click row
              //     // onContextMenu: (event) => {}, // right button click row
              //     // onMouseEnter: (event) => {}, // mouse enter row
              //     // onMouseLeave: (event) => {}, // mouse leave row
              //   };
              // }}
            />
          </Spin>
        </div>

        <Modal
          title={false}
          style={{ top: 20 }}
          visible={modal1Visible}
          onOk={() => removeTag(value)}
          onCancel={() => setModal1Visible(false)}
          Header={false}
        >
          <h4>Are you sure you want to delete this ?</h4>
        </Modal>
      </Spin>
    </Fragment>
  );
};

export default ClientEmailHistory;
